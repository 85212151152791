function setupSwiper() {
  new Swiper('.swiper-container', {
    loop: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
    },
  })

  new Swiper('.company-container', {
    loop: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
    },
  })
}

function setupFontSize() {
  const html = document.documentElement
  const width = window.innerWidth
  const base = 375

  html.style.fontSize = `${(width / base) * 20}px`
}

document.addEventListener('DOMContentLoaded', () => {
  const hiddenCheckbox = document.querySelector('.hidden-checkbox')
  const blocks = document.querySelectorAll('.navi-block')
  const naviItems = document.querySelectorAll('.navi__list-item')

  let timerId = 0
  const highlightCurrent = () => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      // const blocks = document.querySelectorAll('.navi-block')
      const naviItems = document.querySelectorAll('.navi__list-item')

      let isContinue = true
      blocks.forEach((block) => {
        if (!isContinue) return
        const { top } = block.getBoundingClientRect()
        if (top > 0 && top < window.innerHeight) {
          try {
            naviItems.forEach(item => item.classList.remove('navi__list-item--active'))
            document.querySelector(`.${block.dataset.naviItem}`).classList.add('navi__list-item--active')
            isContinue = false
          } catch (err) {
            //
          }
        }
      })
    }, 10)
  }


  document.addEventListener('scroll', highlightCurrent)
  window.addEventListener('resize', setupFontSize)
  document.addEventListener('resize', highlightCurrent)

  naviItems.forEach(item => {
    item.addEventListener('click', () => {
      setTimeout(() => {
        hiddenCheckbox.checked = false
      }, 200)
    })
  })

  setupFontSize()

  highlightCurrent()

  setupSwiper()

  // ScrollReveal().reveal('.partial-title', {
    // delay: 100,
    // // duration: 1000,
    // // cleanup: true,
    // reset: true,
  // })
})


